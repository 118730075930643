<template>
  <small>Belgische tijd: {{ datetime }}</small>
</template>

<script>
import { computed } from 'vue'
import { DateTime } from 'luxon'

export default {
  setup() {
    const datetime = computed(() => DateTime.now().setZone('Europe/Paris').toFormat('T'))
    return { datetime }
  },
}
</script>

<style></style>
